import 'react-toggle/style.css';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';


export const PackageDetailStep = ({ onPrev, onNext, activeTab, formData, setFormData, editErrors, seteditErrors }) => {
    const [errors, setErrors] = useState({});

    const handleValidation = () => {
        const { invoice_amount, } = formData?.order_details;
        const { weight, length, breadth, height } = formData?.dimension_details;

        const errorsObj = {};

        if (!invoice_amount) {
            errorsObj.invoice_amount = "Invoice Amount is required!";

        }
        if (weight <= 0) {
            errorsObj.weight = "Dead Weight should be greater than 0!";
        }
        if (!weight) {
            errorsObj.weight = "Dead Weight is required!";
        }
        if (length <= 0) {
            errorsObj.length = "Length should be greater than 0!";
        }
        if (!length) {
            errorsObj.length = "Length is required!";
        }
        if (breadth <= 0) {
            errorsObj.breadth = "Breadth should be greater than 0!";
        }
        if (!breadth) {
            errorsObj.breadth = "Breadth is required!";
        }
        if (height <= 0) {
            errorsObj.height = "Height should be greater than 0!";
        }
        if (!height) {
            errorsObj.height = "Height is required!";
        }
        setErrors(errorsObj);
        return Object.keys(errorsObj).length === 0;
    };

    const handleNext = () => {
        const isValid = handleValidation();
        if (isValid) {
            onNext();
        }
    };

    const handleChangeOrder = (e, field) => {
        const value = e.target.value.trim();
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value) || value === '') {
            setFormData(prevData => ({
                ...prevData,
                order_details: {
                    ...prevData.order_details,
                    [field]: value
                }
            }));
        }
    };
    const handleChangeCharge = (e, field) => {
        const charge = e.target.value.trim();
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(charge) || charge === '') {
            setFormData(prevData => ({
                ...prevData,
                charge_details: {
                    ...prevData.charge_details,
                    [field]: charge
                }
            }));
        }
    };

    const handleChangeDimension = (e, field) => {
        let charge = e.target.value.trim();
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(charge) || charge === '') {
            if (field === 'weight' && charge.includes('.')) {
                const parts = charge.split('.');
                if (parts[1].length > 2) {
                    charge = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
            }
            if (field === 'weight') {
                setFormData(prevData => ({
                    ...prevData,
                    dimension_details: {
                        ...prevData.dimension_details,
                        [field]: charge
                    }
                }));
            }
            else {
                setFormData(prevData => ({
                    ...prevData,
                    dimension_details: {
                        ...prevData.dimension_details,
                        [field]: charge
                    }
                }));
            }
        }
    };

    const [finalWeight, setFinalWeight] = useState(0)



    const vol_data = formData.dimension_details.length * formData.dimension_details.breadth * formData.dimension_details.height / 5000;
    const chargedWeight = formData?.dimension_details.weight;


    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            dimension_details: {
                ...prevData.dimension_details,
                vol_weight: vol_data.toFixed(2)
            }
        }));
    }, [vol_data])

    useEffect(() => {
        if (vol_data && chargedWeight) {
            if (vol_data >= chargedWeight) {
                setFinalWeight(vol_data);
            } else {
                setFinalWeight(chargedWeight);
            }
        } else if (vol_data) {
            setFinalWeight(vol_data);
        } else if (chargedWeight) {
            setFinalWeight(chargedWeight);
        }
    }, [vol_data, chargedWeight]);

    useEffect(() => {
        if (activeTab) {
            setErrors({})
        }
    }, [activeTab])

    return (
        <div>
            <div className='box-shadow shadow-sm p10 w-100 form-box-h'>
                <div className='inputs-container mx-auto mb-3'>
                    {/* Step 4 content */}
                    <h3 className='mb-4'>Package Details</h3>
                    <div className='row row-gap-3'>
                        {/* Invoice Amount */}
                        <label className='col'>
                            <span>Invoice Amount (₹) <span className='mandatory'>*</span></span>
                            <input
                                className={`input-field ${errors.invoice_amount || editErrors?.invoice_amount ? 'input-field-error' : ''}`}
                                type="text" inputMode='numeric' value={formData.order_details.invoice_amount} onChange={(e) => handleChangeOrder(e, 'invoice_amount')}
                                onKeyPress={(e) => {
                                    const allowedCharacters = /^[0-9\b.]+$/;
                                    if (!allowedCharacters.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter invoice amount'
                            />
                            {(errors.invoice_amount || editErrors?.invoice_amount) && <span className="custom-error">{errors.invoice_amount || editErrors?.invoice_amount}</span>}
                        </label>

                        {/* COD Charges */}
                        <label className='col'>
                            <span>COD Charges (₹) <span className='text-gray'>(Optional)</span></span>
                            <input
                                inputMode='numeric'
                                className='input-field'
                                type="text" value={formData.charge_details.cod_charges} onChange={(e) => handleChangeCharge(e, 'cod_charges')}
                                onKeyPress={(e) => {
                                    if (!/[\d.]/.test(e.key) || (e.key === '.' && e.target.value.includes('.'))) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter COD charges'
                            />
                        </label>
                    </div>
                    <hr />
                    <div className=''>
                        <div className='fw-bold lh-base'>Dead Weight <span className='mandatory'>*</span>
                            {/* {errors.weight && <span className="custom-error">{errors.weight}</span>} */}
                            <label>
                                <input
                                    // className='input-field'
                                    className={`input-field ${errors.weight || editErrors?.weight ? 'input-field-error' : ''}`}
                                    style={{ minWidth: '15    0px' }}
                                    inputMode='numeric'
                                    type="text" value={formData.dimension_details.weight}
                                    onChange={(e) => handleChangeDimension(e, 'weight')}
                                    onKeyPress={(e) => {
                                        const allowedCharacters = /^[0-9\b.]+$/;
                                        if (!allowedCharacters.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    placeholder='Enter dead weight'
                                />
                                <span class="unit">KG</span>
                                {(errors.weight || editErrors?.weight) && <span className="custom-error fw-normal">{errors.weight || editErrors?.weight}</span>}
                            </label>
                            <br />
                            <span className="font12 fw-normal">Dead Weight is physical Weight
                            </span>
                        </div>
                        <label className='col'>

                        </label>
                    </div>
                    <div className='mt-4'>
                        <p className='fw-bold lh-base'>Volumetric Weight <span className='mandatory'>*</span><br />
                            <span className="font12 fw-normal">Enter packages dimensions to calculate Volumetric Weight
                            </span>
                        </p>
                    </div>
                    <div className="row gap-2 row-gap-4">
                        {/* Length (cm) */}
                        <label className='col'>
                            Length
                            <input
                                className={`input-field ${errors.length || editErrors?.length ? 'input-field-error' : ''}`}
                                type="text" value={formData?.dimension_details?.length}
                                onChange={(e) => handleChangeDimension(e, 'length')}
                                onKeyPress={(e) => {
                                    const allowedCharacters = /^[0-9\b.]+$/;
                                    if (!allowedCharacters.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter length'
                            />
                            <span class="unit pd-lbh">CM</span>
                            {(errors.length || editErrors?.length) && <span className="custom-error">{errors.length || editErrors?.length}</span>}

                        </label>

                        {/* Breadth (cm) */}
                        <label className='col'>
                            Breadth
                            <input
                                className={`input-field ${errors.breadth || editErrors?.breadth ? 'input-field-error' : ''}`}
                                type="text" value={formData.dimension_details.breadth} onChange={(e) => handleChangeDimension(e, 'breadth')}
                                onKeyPress={(e) => {
                                    const allowedCharacters = /^[0-9\b.]+$/;
                                    if (!allowedCharacters.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter breadth'
                            />
                            <span class="unit pd-lbh">CM</span>
                            {(errors.breadth || editErrors?.breadth) && <span className="custom-error">{errors.breadth || editErrors?.breadth}</span>}
                        </label>

                        {/* Height (cm) */}
                        <label className='col'>
                            Height
                            <input
                                className={`input-field ${errors.height || editErrors?.height ? 'input-field-error' : ''}`}
                                type="text" value={formData.dimension_details.height} onChange={(e) => handleChangeDimension(e, 'height')}
                                onKeyPress={(e) => {
                                    const allowedCharacters = /^[0-9\b.]+$/;
                                    if (!allowedCharacters.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter height'
                            />
                            <span class="unit pd-lbh">CM</span>
                            {(errors.height || editErrors?.height) && <span className="custom-error">{errors.height || editErrors?.height}</span>}
                        </label>
                    </div>
                    <div className="volumetric-weight">
                        <label>
                            Chargeable Weight
                            <input className='input-field' type="text" value={parseFloat(finalWeight)?.toFixed(2)} />
                            <span class="unit">KG</span>
                        </label>
                    </div>

                </div>
            </div>
            <div className='d-flex justify-content-end my-3 cof-btn-container'>
                {/* Add more input fields as needed */}
                <button className='btn main-button-outline' onClick={onPrev}>Previous</button>
                <button className='btn main-button ms-3' onClick={handleNext}>Next</button>
            </div>
        </div>
    );
};