import React from 'react'

const AcceptIcon = ({ size }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={size}
                height={size}
                x={0}
                y={0}
                viewBox="0 0 520 520"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <g data-name="60-Checked">
                        <circle
                            cx="219.272"
                            cy="279.439"
                            r="187.272"
                            fill="#1975c9"
                            opacity={1}
                            data-original="#b0ef8f"
                            className=""
                        />
                        <path
                            fill="#c5dcf1"
                            d="M226.155 412.02 83.475 258.044l40.094-65.418 101.924 81.9L409.162 55.772l75.7 80.243z"
                            opacity={1}
                            data-original="#009045"
                            className=""
                        />
                    </g>
                </g>
            </svg>

        </>
    )
}

export default AcceptIcon