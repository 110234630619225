import React from 'react'

const PreviewIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={40}
                height={40}
                x={0}
                y={0}
                viewBox="0 0 512.134 512.134"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        d="M333.082 340.365h65.066v52.266h-65.066z"
                        style={{}}
                        transform="rotate(135.178 365.616 366.499)"
                        fill="#334a5e"
                        data-original="#334a5e"
                    />
                    <path
                        d="M359.334 61.734c-82.133-82.133-214.4-82.133-296.533-1.067s-82.133 214.4-1.067 296.533 214.4 82.133 296.533 1.067 82.134-214.4 1.067-296.533z"
                        style={{}}
                        fill="#40596b"
                        data-original="#40596b"
                        className=""
                    />
                    <path
                        d="M93.734 325.201c-64-64-64-168.533 1.067-232.533 64-64 168.533-64 232.533 1.067 64 64 64 168.533-1.067 232.533-64 63.999-168.533 63.999-232.533-1.067z"
                        style={{}}
                        fill="#f2f2f2"
                        data-original="#f2f2f2"
                        className=""
                    />
                    <path
                        d="M309.201 309.201c-54.4 53.333-144 53.333-197.333-1.067-54.4-54.4-54.4-142.933 1.067-197.333s141.867-54.4 196.267 0c54.399 55.466 54.399 144-.001 198.4z"
                        style={{}}
                        fill="#54c0eb"
                        data-original="#54c0eb"
                        className=""
                    />
                    <path
                        d="M275.067 275.067c-35.2 35.2-92.8 35.2-129.067 0-35.2-35.2-35.2-92.8 0-129.067 35.2-35.2 92.8-35.2 129.067 0 36.267 36.267 36.267 93.867 0 129.067z"
                        style={{}}
                        fill="#40596b"
                        data-original="#40596b"
                        className=""
                    />
                    <path
                        d="m492.667 405.201-66.133-66.133c-4.267-4.267-10.667-4.267-14.933 0l-73.6 73.6c-4.267 4.267-4.267 10.667 0 14.933l66.133 66.133c24.533 24.533 64 24.533 88.533 0 24.534-24.533 24.534-64 0-88.533z"
                        style={{}}
                        fill="#ff7058"
                        data-original="#ff7058"
                    />
                    <circle
                        cx="168.401"
                        cy="149.201"
                        r="28.8"
                        style={{}}
                        fill="#ffffff"
                        data-original="#ffffff"
                        className=""
                    />
                    <circle
                        cx="206.801"
                        cy="186.534"
                        r="17.067"
                        style={{}}
                        fill="#ffffff"
                        data-original="#ffffff"
                        className=""
                    />
                </g>
            </svg>
        </>
    )
}

export default PreviewIcon