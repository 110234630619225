import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportIcon from '../../../OrdersPage/Components/BulkActionsComponent/Components/BulkIcons/ExportIcon';
import AcceptIcon from '../../../OrdersPage/Components/BulkActionsComponent/Components/BulkIcons/AcceptIcon';
import BulkDisputeIcon from '../../../OrdersPage/Components/BulkActionsComponent/Components/BulkIcons/BulkDisputeIcon';

const BulkActionsComponent = ({ activeTab, selectedRows }) => {
    const dispatch = useDispatch()
    const [exportButtonClick, setExportButtonClick] = useState(false)
    const exportCard = useSelector(state => state?.exportSectionReducer?.exportWeightCard)

    const handleExport = () => {
        setExportButtonClick(true);
        const requestData = {
            "order_tab": {
                "type": activeTab,
                "subtype": ""
            },
            "order_id": `${selectedRows.join(',')}`,
            "courier": "",
            "awb_number": "",
            "min_awb_assign_date": "",
            "max_awb_assign_date": "",
            "status": "",
            "order_type": "",
            "customer_order_number": "",
            "channel": "",
            "min_invoice_amount": "",
            "max_invoice_amount": "",
            "warehouse_id": "",
            "product_name": "",
            "delivery_address": "",
            "min_weight": "",
            "max_weight": "",
            "min_product_qty": "",
            "max_product_qty": "",
            "rto_status": false,
            "global_type": "",
            "payment_type": ""
        };
        dispatch({ type: "EXPORT_WEIGHT_DATA_ACTION", payload: requestData });
    };

    useEffect(() => {
        if (exportButtonClick) {
            var FileSaver = require('file-saver');
            var blob = new Blob([exportCard], { type: 'application/ms-excel' });
            FileSaver.saveAs(blob, `${activeTab}.xlsx`);
            setExportButtonClick(false);
        }
    }, [exportCard]);

    return (
        <>
            {selectedRows.length > 0 && (
                <section className='bulk-action-container box-shadow'>
                    <div className='ba-inner-container'>
                        <div className='ba-rows-selected'>
                            <span className='fw-bold font20'>{selectedRows.length}</span>
                            <span>Rows Selected</span>
                        </div>
                        <ul className='ba-actions'>
                            {activeTab === "Weight Reconciliation" &&
                                <>
                                    {/* <li><AcceptIcon size={24} /><span>Accept</span></li> */}
                                    {/* <li><BulkDisputeIcon size={24} /><span>Dispute</span></li> */}
                                    <li onClick={handleExport}><ExportIcon /><span>Export</span></li>
                                    {/* <li><ExportIcon /><span>Export All</span></li> */}
                                </>
                            }
                            {activeTab === "Settled Reconciliation" &&
                                <>
                                    <li onClick={handleExport}><ExportIcon /><span>Export</span></li>
                                    {/* <li><ExportIcon /><span>Export All</span></li> */}
                                </>
                            }
                            {activeTab === "On-Hold Reconciliation" &&
                                <>
                                    <li onClick={handleExport}><ExportIcon /><span>Export</span></li>
                                    {/* <li><ExportIcon /><span>Export All</span></li> */}
                                </>
                            }
                        </ul>
                        <div className='ba-close'></div>
                    </div>
                </section>
            )}
        </>
    )
}

export default BulkActionsComponent