import axios from 'axios';
import 'react-toggle/style.css';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { OrderDetailsStep } from './create-order-steps/OrderDetailsStep';
import { AddressDetailStep } from './create-order-steps/AddressDetailStep';
import { ProductDetailStep } from './create-order-steps/ProductDetailStep';
import { PackageDetailStep } from './create-order-steps/PackageDetailStep';
import { WareHouseDetailStep } from './create-order-steps/WareHouseDetailStep';
import { BASE_URL_ORDER } from '../../../../../../../axios/config';
import { customErrorFunction, } from '../../../../../../../customFunction/errorHandling';

const DomesticCreateOrder = (activeTab) => {
    const totalSteps = 5;
    const navigation = useNavigate();
    const [step, setStep] = useState(1);
    const authToken = Cookies.get("access_token")
    const currentDate = new Date();
    const [isChecked, setIsChecked] = useState(true);
    const [progressBarWidth, setProgressBarWidth] = useState('5%');

    const [formData, setFormData] = useState({
        order_details: {
            customer_order_number: '',
            invoice_amount: '',
            is_mps: false,
            warehouse_id: '',
            order_tag: [],
            payment_type: '',
            order_date: currentDate,
            order_type: "",
            channel: "custom",
            channel_id: null
        },
        shipping_details: {
            recipient_name: "",
            address: "",
            landmark: "",
            country: "India",
            state: "",
            city: "",
            pincode: "",
            mobile_number: "",
            email: "",
            company_name: "",
            contact_code: "91"
        },
        billing_details: {
            customer_name: "",
            address: "",
            landmark: "",
            country: "India",
            state: "",
            city: "",
            pincode: "",
            mobile_number: "",
            email: "",
            company_name: "",
            contact_code: "91"
        },
        other_details: {
            number_of_packets: 0,
            reseller_name: ""
        },
        charge_details: {
            cod_charges: '',
            shipping_charges: '',
            transaction_fee: '',
            is_gift_wrap: false || true
        },
        dimension_details: {
            weight: '',
            length: '',
            breadth: '',
            height: '',
            vol_weight: ''
        },
        product_details: [
            {
                product_name: "",
                quantity: '',
                unit_price: '',
                product_category: "",
                weight: 0,
                sku: "",
                hsn_code: "",
                tax_rate: null,
                product_discount: '',
                hts_number: "",
                export_reference_number: ""
            }
        ],
    })

    useEffect(() => {
        const updateProgressBarWidth = () => {
            const width = step > totalSteps ? '100%' : `${((step - 1) / totalSteps) * 100}%`;
            setProgressBarWidth(width);
        };
        updateProgressBarWidth();
    }, [step, totalSteps]);

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    const handleFormSubmit = async () => {
        try {
            const response = await axios.post(`${BASE_URL_ORDER}/orders-api/orders/`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response !== null) {
                if (response.status === 201) {
                    toast.success("Order Created successfully!")
                    navigation('/Orders');
                }
            }
        } catch (error) {
            customErrorFunction(error)
        }
    };

    useEffect(() => {
        if (activeTab) {
            // setStep(1)
            // setIsChecked(true)
            // setFormData({
            //     order_details: {
            //         customer_order_number: '',
            //         invoice_amount: '',
            //         is_mps: false,
            //         warehouse_id: '',
            //         order_tag: [],
            //         payment_type: '',
            //         order_date: currentDate,
            //         order_type: "",
            //         channel: "custom",
            //         channel_id: null
            //     },
            //     shipping_details: {
            //         recipient_name: "",
            //         address: "",
            //         landmark: "",
            //         country: "India",
            //         state: "",
            //         city: "",
            //         pincode: "",
            //         mobile_number: "",
            //         email: "",
            //         company_name: "",
            //         contact_code: "91"
            //     },
            //     billing_details: {
            //         customer_name: "",
            //         address: "",
            //         landmark: "",
            //         country: "India",
            //         state: "",
            //         city: "",
            //         pincode: "",
            //         mobile_number: "",
            //         email: "",
            //         company_name: "",
            //         contact_code: "91"
            //     },
            //     other_details: {
            //         number_of_packets: 0,
            //         reseller_name: ""
            //     },
            //     charge_details: {
            //         cod_charges: '',
            //         shipping_charges: '',
            //         transaction_fee: '',
            //         is_gift_wrap: false || true
            //     },
            //     dimension_details: {
            //         weight: '',
            //         length: '',
            //         breadth: '',
            //         height: '',
            //         vol_weight: ''
            //     },
            //     product_details: [
            //         {
            //             product_name: "",
            //             quantity: '',
            //             unit_price: '',
            //             product_category: "",
            //             weight: 0,
            //             sku: "",
            //             hsn_code: "",
            //             tax_rate: null,
            //             product_discount: '',
            //             hts_number: "",
            //             export_reference_number: ""
            //         }
            //     ],
            // })
        }
    }, [activeTab])


    return (
        <div className="stepper-form-container">
            <div className='box-shadow shadow-sm p10 w-100 steps-header mb-4'>
                <div>
                    <div className={`stepper-line mx-auto mb-3`}>
                        {/* Stepper line with markers for each step */}
                        <div className={`step-marker ${step > 2 ? 'completed' : ''}`}>
                            <span className={`${step > 1 ? 'completed' : ''}`}>1</span>
                            Order Details
                        </div>
                        <div className={`step-marker ${step > 3 ? 'completed' : ''}`}>
                            <span className={`${step > 2 ? 'completed' : ''}`}>2</span>
                            Shipping Details
                        </div>
                        <div className={`step-marker ${step > 4 ? 'completed' : ''}`}>
                            <span className={`${step > 3 ? 'completed' : ''}`}>3</span>
                            Product Details
                        </div>
                        <div className={`step-marker ${step > 5 ? 'completed' : ''}`}>
                            <span className={`${step > 4 ? 'completed' : ''}`}>4</span>
                            Package Details
                        </div>
                        <div className={`step-marker ${step > 6 ? 'completed' : ''}`}>
                            <span className={`${step > 5 ? 'completed' : ''}`}>5</span>
                            Warehouse Details
                        </div>
                    </div>
                </div>
                <div className="progress-container">
                    {/* Manual Progress Bar */}
                    <div className="progress-bar" style={{ width: progressBarWidth }}></div>
                </div>
                <div className="progress-container">
                    {/* Manual Progress Bar */}
                    <div className="progress-bar" style={{ width: '2%' }}></div>
                </div>
            </div>

            <div className='w-100'>
                <div className=''>
                    {/* Steps */}
                    {step === 1 && (
                        <OrderDetailsStep
                            onNext={handleNext}
                            activeTab={activeTab}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    )}
                    {step === 2 && (
                        <AddressDetailStep
                            onPrev={handlePrev}
                            activeTab={activeTab}
                            onNext={handleNext}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    )}
                    {step === 3 && (
                        <ProductDetailStep
                            onPrev={handlePrev}
                            onNext={handleNext}
                            formData={formData}
                            activeTab={activeTab}
                            setFormData={setFormData}
                        />
                    )}
                    {step === 4 && (
                        <PackageDetailStep
                            onPrev={handlePrev}
                            onNext={handleNext}
                            formData={formData}
                            activeTab={activeTab}
                            setFormData={setFormData}
                        />
                    )}
                    {step === 5 && (
                        <WareHouseDetailStep
                            activeTab={activeTab}
                            onPrev={handlePrev}
                            onSubmit={handleFormSubmit}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DomesticCreateOrder;