import React from 'react'

const BulkDisputeIcon = ({ size }) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={size}
                height={size}
                x={0}
                y={0}
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
                className=""
            >
                <g>
                    <path
                        fill="#60a9eb"
                        d="M463.988 240.59C487.7 216.172 502 184.55 502 150c0-77.32-77.629-140-166-140S176 72.68 176 150l160 140c23.61 0 52.031-4.48 72.21-12.512C426.489 297.468 452.782 310 482 310c-18.79-18.79-24.8-45.54-18.012-69.41zm0 0"
                        opacity={1}
                        data-original="#e87288"
                        className=""
                    />
                    <path
                        fill="#c5dcf1"
                        d="M258.45 170C304.921 194.5 336 239.07 336 290c0 77.32-71.629 140-160 140-23.61 0-52.031-4.48-72.21-12.512C85.511 437.468 59.218 450 30 450c18.79-18.79 24.8-45.54 18.012-69.41C24.3 356.172 10 324.55 10 290c0-77.32 77.629-140 166-140 30.16 0 58.379 7.3 82.45 20zm0 0"
                        opacity={1}
                        data-original="#cae9fc"
                        className=""
                    />
                    <path
                        d="M256 100c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM90 280c5.52 0 10-4.48 10-10s-4.48-10-10-10-10 4.48-10 10 4.48 10 10 10zm0 0"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M336 0c-90.027 0-163.918 62.07-169.633 140.254C80.63 144.554 0 206.379 0 290c0 34.945 13.828 68.805 39 95.633 4.98 20.531-1.066 42.293-16.07 57.297A9.998 9.998 0 0 0 30 460c28.52 0 56.004-11.184 76.426-30.89C126.32 435.89 152.277 440 176 440c90.016 0 163.898-62.055 169.629-140.223 20.937-.93 42.715-4.797 59.945-10.668C425.996 308.816 453.48 320 482 320a9.998 9.998 0 0 0 7.07-17.07c-15.004-15.004-21.05-36.766-16.07-57.297 25.172-26.828 39-60.688 39-95.633C512 63.113 425.16 0 336 0zM176 420c-23.602 0-50.496-4.633-68.512-11.8a10 10 0 0 0-11.078 2.538c-12.074 13.2-27.773 22.403-44.879 26.633a80.872 80.872 0 0 0 6.098-59.52 9.98 9.98 0 0 0-2.445-4.226C32.496 350.258 20 320.559 20 290c0-70.469 71.438-130 156-130 79.852 0 150 55.527 150 130 0 71.684-67.29 130-150 130zm280.816-186.375a10.027 10.027 0 0 0-2.445 4.227 80.872 80.872 0 0 0 6.098 59.52c-17.106-4.227-32.805-13.435-44.88-26.634a10.007 10.007 0 0 0-11.077-2.539c-15.614 6.211-37.887 10.512-58.914 11.551-2.922-37.816-21.786-73.36-54.036-99.75H422c5.523 0 10-4.477 10-10s-4.477-10-10-10H260.84c-22.7-11.555-48.188-18.293-74.422-19.707C192.164 73.129 257.058 20 336 20c84.563 0 156 59.531 156 130 0 30.559-12.496 60.258-35.184 83.625zm0 0"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                    <path
                        d="M256 260H130c-5.523 0-10 4.477-10 10s4.477 10 10 10h126c5.523 0 10-4.477 10-10s-4.477-10-10-10zM256 320H90c-5.523 0-10 4.477-10 10s4.477 10 10 10h166c5.523 0 10-4.477 10-10s-4.477-10-10-10zM422 100H296c-5.523 0-10 4.477-10 10s4.477 10 10 10h126c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0"
                        fill="#1975c9"
                        opacity={1}
                        data-original="#000000"
                        className=""
                    />
                </g>
            </svg>

        </>
    )
}

export default BulkDisputeIcon