import CustomIcon from "../../../assets/image/integration/CustomIcon.png"
import amazon from "../../../assets/image/logo/AmazonLogo.png"
import magento from "../../../assets/image/integration/magento.png"
import opencart from "../../../assets/image/integration/OpenCart.png"
import api from "../../../assets/image/integration/APIChannelIcon.png"
import shopifyImg from "../../../assets/image/integration/shopify.png"
import woocommerce from "../../..//assets/image/integration/WCLogo.png"
import amazondirect from "../../../assets/image/integration/AmazonLogo.png"
import storehippo from "../../../assets/image/integration/StoreHippoLogo.png"
import unicommerce from "../../../assets/image/integration/UnicommerceIcon.png"
import easyecom from "../../../assets/image/integration/EasyComLogoOMSLogo.png"
import omsguru from "../../../assets/image/logo/OmsGuruIcon.png";


export const channelData = [
    {
        channel_name: "shopify",
        image: shopifyImg
    },
    {
        channel_name: "woocommerce",
        image: woocommerce
    },
    {
        channel_name: "opencart",
        image: opencart
    },
    {
        channel_name: "storehippo",
        image: storehippo
    },
    {
        channel_name: "magento",
        image: magento
    },
    {
        channel_name: "amazon",
        image: amazon
    },
    {
        channel_name: "amazon_direct",
        image: amazondirect
    },
    {
        channel_name: "unicommerce",
        image: unicommerce
    },
    {
        channel_name: "api",
        image: api
    },
    {
        channel_name: "custom",
        image: CustomIcon,
    },
    {
        channel_name: "easyecom",
        image: easyecom
    },
    {
        channel_name: "omsguru",
        image: omsguru
    },

]

